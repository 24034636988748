
import { Api } from 'lib/api'
import { IStatus } from 'lib/types/response'

import { ICreator } from '../types'

import { fromDataToPayload } from './adapter'

export const updateCreatorSingle = (id: string, data: ICreator) => {
  return Api
    .post<IStatus>(`partner/agency/streamer/${id}/save`, fromDataToPayload(data))
    .then(res => res)
}
