import { render, staticRenderFns } from "./CreatorsListTable.vue?vue&type=template&id=6008c192&scoped=true&"
import script from "./CreatorsListTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CreatorsListTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CreatorsListTable.vue?vue&type=style&index=0&id=6008c192&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6008c192",
  null
  
)

export default component.exports