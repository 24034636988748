import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
    const isYoutubeUrl = youtubeRegex.test(value)

    if (!isYoutubeUrl) {
      callback(new Error(i18n.tc('validator.youtubeUrl')))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Blur, Trigger.Change],
}
