import { Api } from 'lib/api'
import { Types } from 'lib/helpers/parseSlug'
import { IResponseData } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICampaignResponse } from './types'

export const getCampaignSingle = (slug: string, typeCampaigns: Types.BA | Types.PF | Types.VOD) => {
  return Api
    .get<IResponseData<ICampaignResponse>>(`partner/agency/${typeCampaigns}/${slug}/info`)
    .then(res => fromResponseToData(res.data))
}
