
import { Api } from 'lib/api'
import { Types } from 'lib/helpers/parseSlug'
import { IResponse } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICampaignCreatorsResponse } from './types'

export const getCampaignCreators = (slug: string, page: number, typeCampaigns: Types.BA | Types.PF | Types.VOD) => {
  return Api
    .get<IResponse<ICampaignCreatorsResponse>>(`partner/agency/${typeCampaigns}/${slug}/report`, { page })
    .then(res => fromResponseToData(res))
}

// https://www.uplify.us/api/v1/partner/agency/preroll/{slug}/report
