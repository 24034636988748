
import { Api } from 'lib/api'
import { IResponse } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICreatorsResponse } from './types'

export const getCreatorsList = (data: { page: number }) => {
  return Api
    .get<IResponse<ICreatorsResponse>>('partner/agency/streamers', data)
    .then(res => fromResponseToData(res))
}
