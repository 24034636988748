import bic from 'lib/validators/bic'
import email from 'lib/validators/email'
import phone from 'lib/validators/phone'
// import phoneNumber from 'lib/validators/phoneNumber'
import required from 'lib/validators/required'
import swift from 'lib/validators/swift'

export default function validationRules (model) {
  return {
    name: [
      required,
    ],
    email: [
      required,
      email,
    ],
    address: [
      required,
    ],
    phone: [
      phone,
      required,
    ],
    bank_name: [
      required,
    ],
    bank_account_name: [
      required,
    ],
    bank_account_holder_address: [
      required,
    ],
    bank_address: [
      required,
    ],
    bank_account_number: [
      required,
    ],
    swift_code: [
      required,
      swift,
    ],
    routing_number: [
      required,
      bic,
    ],
  }
}
