import { ICampaign, ICampaignResponse } from './types'

export const fromResponseToData = (response: ICampaignResponse): ICampaign => ({
  slug: response.slug,
  platform: response.platform,
  title: response.title,
  start: response.start,
  end: response.end,
  bidCap: response.bid_cap,
  currency: response.currency,
  frequency: response.frequency,
  format: response.format,
  description: response.description,
  ads: response.ads,
  campaign: response.campaign,
  advertiser: response.campaign.advertiser,
  holding: response.campaign.holding,
  daily_action_limit: response.daily_action_limit,
  cpa_payout_type: response.cpa_payout_type,
  payout_type: response.payout_type,
  attachments: response.attachments,
  video_description_text: response.video_description_text,
})
