import { ICreator, ICreatorResponse } from '../types'

export const fromDataToPayload = (data: ICreator): ICreatorResponse => ({
  external_cpm: {
    video: +data.externalCpm.video || null,
    pip_video: +data.externalCpm.pip || null,
    custom: +data.externalCpm.custom || null,
    interactive: +data.externalCpm.interactive || null,
    preroll: +data.externalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  internal_cpm: {
    video: +data.internalCpm.video || null,
    pip_video: +data.internalCpm.pip || null,
    custom: +data.internalCpm.custom || null,
    interactive: +data.internalCpm.interactive || null,
    preroll: +data.internalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_external_cpm: {
    video: +data.darkMarketExternalCpm.video || null,
    pip_video: +data.darkMarketExternalCpm.pip || null,
    custom: +data.darkMarketExternalCpm.custom || null,
    interactive: +data.darkMarketExternalCpm.interactive || null,
    preroll: +data.darkMarketExternalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_internal_cpm: {
    video: +data.darkMarketInternalCpm.video || null,
    pip_video: +data.darkMarketInternalCpm.pip || null,
    custom: +data.darkMarketInternalCpm.custom || null,
    interactive: +data.darkMarketInternalCpm.interactive || null,
    preroll: +data.darkMarketInternalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
})
