import { defineStore } from 'pinia'

import {
  getCreatorCampaigns,
  getCreatorSingle,
  getCreatorsList,
  ICreator,
  ICreatorCampaignsList,
  ICreators,
  updateCreatorSingle,
} from 'lib/api/modules/partner/creators/creators'
import { Logger } from 'lib/helpers/handleLog'

interface State {
  isFetchingData: boolean
  list: null | ICreators
  listCurrentPage: number
  creator: null | ICreator
  creatorCampaigns: null | ICreatorCampaignsList
  creatorId: null | number
  settingsSidebarVisible: boolean
  campaignsSidebarVisible: boolean
  isFormUpdating: boolean
}

export const useCreatorsStore = defineStore({
  id: 'partner-creators',

  state: (): State => ({
    isFetchingData: false,
    isFormUpdating: false,
    list: null,
    listCurrentPage: 1,
    creator: null,
    creatorCampaigns: null,
    creatorId: null,
    settingsSidebarVisible: false,
    campaignsSidebarVisible: false,
  }),

  actions: {
    async getCreators (page = 1, append = false) {
      try {
        this.isFetchingData = true
        const res = await getCreatorsList({ page })
        this.listCurrentPage = page
        if (append && this.list?.data) {
          this.list.data = [...this.list?.data, ...res.data]
        }
        else {
          this.list = res
        }
      }
      finally {
        this.isFetchingData = false
      }
    },

    async getCreator (id: number) {
      this.settingsSidebarVisible = true

      if (typeof id !== 'number') {
        Logger.warning('Creator if is not of type \'number\'')
      }

      if (this.creatorId === id) {
        return
      }

      try {
        this.isFetchingData = true
        this.creatorId = id
        const res = await getCreatorSingle(id.toString())
        this.creator = res
      }
      finally {
        this.isFetchingData = false
      }
    },

    async getCampaigns (id: number) {
      this.campaignsSidebarVisible = true

      if (typeof id !== 'number') {
        Logger.warning('Creator if is not of type \'number\'')
      }

      if (this.creatorId === id) {
        return
      }

      try {
        this.isFetchingData = true
        const res = await getCreatorCampaigns(id.toString())
        this.creatorId = id
        this.creatorCampaigns = res
      }
      finally {
        this.isFetchingData = false
      }
    },

    async updateCreator () {
      if (!this.creatorId || !this.creator) {
        Logger.warning('No creator currently selected')
        return
      }

      try {
        this.isFetchingData = true
        await updateCreatorSingle(this.creatorId.toString(), this.creator)
      }
      finally {
        this.isFetchingData = false
      }
    },

    _getCurrentListItemCreator () {
      return this.list?.data.find(creator => creator.id === this.creatorId)
    },
  },
})
