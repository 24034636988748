import { render, staticRenderFns } from "./CampaignsSidebarInfoStreamer.vue?vue&type=template&id=21e41dec&scoped=true&"
import script from "./CampaignsSidebarInfoStreamer.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignsSidebarInfoStreamer.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignsSidebarInfoStreamer.vue?vue&type=style&index=0&id=21e41dec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e41dec",
  null
  
)

export default component.exports