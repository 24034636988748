import { render, staticRenderFns } from "./CampaignsSidebarPerformance.vue?vue&type=template&id=63719f62&scoped=true&"
import script from "./CampaignsSidebarPerformance.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignsSidebarPerformance.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignsSidebarPerformance.vue?vue&type=style&index=0&id=63719f62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63719f62",
  null
  
)

export default component.exports