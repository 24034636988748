import { render, staticRenderFns } from "./CampaignsSidebarInfoPlainAwereness.vue?vue&type=template&id=87b6f220&scoped=true&"
import script from "./CampaignsSidebarInfoPlainAwereness.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignsSidebarInfoPlainAwereness.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignsSidebarInfoPlainAwereness.vue?vue&type=style&index=0&id=87b6f220&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b6f220",
  null
  
)

export default component.exports