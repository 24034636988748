import { Api } from 'lib/api'
import { IResponse } from 'lib/types/response'

import { ICampaignsResponse } from '../types'

import { fromResponseToData } from './adapter'
import { Types } from 'lib/helpers/parseSlug'

export const getCampaignsActiveList = (data: { page: number}, typeCampaigns: Types.BA | Types.PF | Types.VOD) => {
  return Api
    .get<IResponse<ICampaignsResponse>>(`partner/agency/${typeCampaigns}/active`, data)
    .then(res => fromResponseToData(res))
}
