import { Api } from 'lib/api'
import { IResponseData, IStatus } from 'lib/types/response'

import { IBilling, IInvoicesResponse } from '../types/IBilling'

import { fromResponseToData, fromResponseToInvoices } from './adapter'

export const getBilling = () => {
  return Api
    .get<IResponseData<IBilling>>('partner/agency/billing')
    .then(res => fromResponseToData(res.data))
}
export const updateBilling = (data: IBilling) => {
  return Api
    .post<IStatus>('partner/agency/billing/save', fromResponseToData(data))
    .then(res => res)
}

export const getInvoices = (page : number) => {
  return Api
    .get<IInvoicesResponse>('agency/wallet/transactions', { page: page })
    .then(res => fromResponseToInvoices(res))
}
