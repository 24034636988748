import { Api } from 'lib/api'
import { Types } from 'lib/helpers/parseSlug'
import { IResponse } from 'lib/types/response'

import { ICampaignsResponse } from '../types'

import { fromResponseToData } from './adapter'

export const getCampaignsClosedList = (data: { page: number }, typeCampaigns: Types.BA | Types.PF | Types.VOD) => {
  return Api
    .get<IResponse<ICampaignsResponse>>(`partner/agency/${typeCampaigns}/closed`, data)
    .then(res => fromResponseToData(res))
}
