import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

// export default {
//   validator: (rule: any, value: any, callback: CallbackFunction) => {
//     // const swiftRegex = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/
//     console.log('validatorvalidator');
//     const swiftRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/
//     if (!swiftRegex.test(value)) {
//       callback(new Error(i18n.tc('validator.swift')))
//     }
//     else {
//       callback()
//     }
//   },
//   trigger: [Trigger.Blur],
// }

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    // Преобразуем значение в строку, если это число
    if (typeof value === 'number') {
      value = value.toString()
    }

    // Проверяем, что значение состоит только из букв и цифр
    if (/[^a-zA-Z0-9]/.test(value)) {
      callback(new Error(i18n.tc('validator.swiftAllowedCharacters')))
      // SWIFT код может содержать только буквы и цифры'
    }
    // Проверяем длину SWIFT кода (8 или 11 символов)
    else if (value.length !== 8 && value.length !== 11) {
      callback(new Error(i18n.tc('validator.swiftLengthDescription')))
      // SWIFT код должен быть длиной 8 или 11 символов
    }

    // Если все проверки пройдены, вызываем callback без ошибки
    callback()
  },
  trigger: [Trigger.Blur],
}
