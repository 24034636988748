import { render, staticRenderFns } from "./CampaignsSidebarInfoPlainPerformance.vue?vue&type=template&id=7e9b1c56&scoped=true&"
import script from "./CampaignsSidebarInfoPlainPerformance.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CampaignsSidebarInfoPlainPerformance.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CampaignsSidebarInfoPlainPerformance.vue?vue&type=style&index=0&id=7e9b1c56&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9b1c56",
  null
  
)

export default component.exports