import { render, staticRenderFns } from "./Tags.vue?vue&type=template&id=09b15890&scoped=true&"
import script from "./Tags.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Tags.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Tags.vue?vue&type=style&index=0&id=09b15890&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b15890",
  null
  
)

export default component.exports