
import { Api } from 'lib/api'
import { IResponseData } from 'lib/types/response'

import { ICreatorResponse } from '../types'

import { fromResponseToData } from './adapter'

export const getCreatorSingle = (id: string) => {
  return Api
    .get<IResponseData<ICreatorResponse>>(`partner/agency/streamer/${id}/info`)
    .then(res => fromResponseToData(res.data))
}
