
import { Api } from 'lib/api'
import { IResponse } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICreatorCampaignsListResponse } from './types'

export const getCreatorCampaigns = (id: string) => {
  return Api
    .get<IResponse<ICreatorCampaignsListResponse>>(`partner/agency/streamer/${id}/report`)
    .then(res => fromResponseToData(res))
}
