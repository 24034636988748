import { CallbackFunction, Trigger } from 'lib/validators/consts/types'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    // Преобразуем число в строку
    if (typeof value === 'number') {
      value = value.toString()
    }

    // Проверяем, если значение пустое
    if (!value) {
      return callback(new Error(i18n.tc('validator.required')))
    }

    // Пытаемся распарсить номер телефона
    try {
      const phoneNumber = parsePhoneNumberFromString(value)

      // Проверяем, является ли номер валидным
      if (!phoneNumber || !phoneNumber.isValid()) {
        return callback(new Error(i18n.tc('validator.invalidPhoneNumber')))
      }

      // Если все в порядке, вызываем callback без ошибки
      callback()
    }
    catch (error) {
      // В случае ошибки парсинга возвращаем ошибку валидации
      callback(new Error(i18n.tc('validator.invalidPhoneNumber')))
    }
  },
  trigger: [Trigger.Blur],
}
