import { ICreatorCampaignsList, ICreatorCampaignsListResponse } from './types'

export const fromResponseToData = (response: ICreatorCampaignsListResponse): ICreatorCampaignsList => {
  return {
    data: response.data.map(campaign => ({
      id: campaign.id,
      title: campaign.title,
      campaign: campaign.campaign,
      format: campaign.format,
      revenue: campaign.revenue,
      impressions: campaign.impressions,
      totalCtr: campaign.total_ctr,
      todayCtr: campaign.today_ctr,
      status: campaign.status,
      restore: campaign.restore,
      slug: campaign.slug,
    })),
  }
}
