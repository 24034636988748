import { ICampaigns, ICampaignsResponse } from '../types'

export const fromResponseToData = (response: ICampaignsResponse): ICampaigns => ({
  total: response.total,
  perPage: response.per_page,
  data: response.data.map(campaign => ({
    id: campaign.id,
    slug: campaign.slug,
    title: campaign.title,
    description: campaign.description,
    campaign: {
      id: campaign.campaign.id,
      slug: campaign.campaign.slug,
      type: campaign.campaign.type,
      title: campaign.campaign.title,
      description: campaign.campaign.description,
      category: campaign.campaign.category,
      visible: campaign.campaign.visible,
    },
    start: campaign.start,
    end: campaign.end,
    total_impressions: campaign.total_impressions, //
    streamers: campaign.streamers,
    published: campaign.published,
    visible: campaign.visible,
    logo: campaign.logo,
    format: campaign.format,
    daily_action_limit: campaign.daily_action_limit
      ? {
        enabled: campaign?.daily_action_limit.enabled,
        today: campaign?.daily_action_limit.today,
        today_limit: campaign?.daily_action_limit.today_limit,
      }
      : undefined,
  })),
})
