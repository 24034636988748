export enum CampaignStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  UNAVAILABLE = 'unavailable',
}

export enum CompletedCampaignStatus {
  OPEN = 'open',
  CLOSE = 'close'
}
