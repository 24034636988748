import { ICreators, ICreatorsResponse } from './types'

export const fromResponseToData = (response: ICreatorsResponse): ICreators => ({
  total: response.total,
  perPage: response.per_page,
  data: response.data.map(creator => ({
    id: creator.id,
    name: creator.name,
    lastActivity: creator.last_activity_at,
    wallet: {
      balance: creator.wallet.balance,
      currency: creator.wallet.currency,
    },
    campaigns: creator.campaigns,
    checkListStatus: creator.check_list_status,
    payableStatus: creator.payable_status,
    ctrStatus: creator.ctr_status,
  })),
})
